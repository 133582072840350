// Variables
@import '../variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@import './base-layout.scss';

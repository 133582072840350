$primary-color: #173b50;
$secondly-color: #b9b16d;
$black-color: #333333;
$white-color: #ffffff;
$gold-color: linear-gradient(135deg, #ded585 0%, #b9b16d 100%);

body, p {
    font-family: 'Noto Sans JP', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Noto Serif', serif;
}

.font-serif {
    font-family: "Noto Serif", serif;
}

.font-sansjp {
    font-family: 'Noto Sans JP', sans-serif;
}

.navbar {
    height: 80px;
    background-color: #111111 !important;
}

.logo-img img{
    max-width: 160px;
    max-height: 40px;
}

.btn-apply {
    color: #ffff;
    background: transparent linear-gradient(105deg, #d8cf89 0%, #b9b16d 100%) 0% 0% no-repeat padding-box;
    font-size: 16px;
}

.btn-apply:hover {
    text-decoration: none;
    color: #111111;
}

.btn-apply {
    color: #ffff;
    background: transparent linear-gradient(105deg, #d8cf89 0%, #b9b16d 100%) 0% 0% no-repeat padding-box;
    font-size: 16px;
}

.btn-apply:hover {
    text-decoration: none;
    color: #111111;
}

.container-fluid {
    display: flex;
    justify-content: space-between;
    color: #ffff;
}

.navbar-collapse {
    display: flex;
    flex-basis: 0% !important;
    justify-content: flex-end;
    color: #ffff;
}

.p-right {
    padding-right: 20px;
}

.navbar-nav {
    display: flex;
    flex-direction: row !important;
}

.nav-link {
    color: #ffff;
}

.ml {
    &-10 {
        margin-left: 10px;
    }

    &-20 {
        margin-left: 20px;
    }
}

.navbar-header .btn-login {
    display: table-cell;
    vertical-align: middle;
    // max-width: 148px;
    padding: 12px 40px;
    background-color: $primary-color;
    color: $white-color;
    font-size: 16px;
    letter-spacing: 1.2px;

    &:hover {
        text-decoration: none;
        background-color: #162839;
    }
}

.navbar-header .btn-register {
    display: table-cell;
    vertical-align: middle;
    // width: 148px;
    background-image: $gold-color;
    color: $white-color;
    font-size: 16px;
    padding: 12px 42px;

    &:hover {
        text-decoration: none;
        background: #94925B;
    }
}

@media (max-width: 990px) {
    .nav-item {
        a {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .navbar-header .btn-login {
        font-size: 12px;
        padding: 10px 34px;
    }

    .navbar-header .btn-register {
        font-size: 12px;
        padding: 10px 34px;
    }
}

//Footer

footer {
    display: flex;
    justify-content: center;
    background-color: #173b50 !important;
    height: 50px;
    align-items: center;
    font-size: 10px;
    padding-left: 43px;
    padding-right: 43px;
}

footer .container-fluid {
    padding: 0;
}

.link-footer a {
    color: #ffff;
    text-decoration: none;
}

.link-footer a:hover {
    text-decoration: none;
    color: #1d68a7;
}

.link-footer a {
    color: #ffff;
    text-decoration: none;
}

.link-footer a:hover {
    text-decoration: none;
    color: #1d68a7;
}

.font-size-28 {
    font-size: 28px;
}

.line-height-42 {
    line-height: 42px;
}

.font-size-16 {
    font-size: 16px;
}

.line-height-30 {
    line-height: 30px;
}

.line-height-24 {
    line-height: 24px;
}

.font-size-20 {
    font-size: 20px;
}

.line-height-28 {
    line-height: 28px;
}

.font-size-12 {
    font-size: 12px;
}

.line-height-18 {
    line-height: 18px;
}

.font-size-14 {
    font-size: 14px;
}

.line-height-19 {
    line-height: 19px;
}

.font-size-10 {
    font-size: 10px;
}

.line-height-15 {
    line-height: 15px;
}

.mb-10 {
    margin-bottom: 6rem;
}

.py-13 {
    padding-top: 13px;
    padding-bottom: 13px;
}

.b-primary {
    color: #ffffff;
    background-color: #173B50;
}

.b-secondly {
    color: #ffffff;
    background-color: #B9B16D;
}

.b-gold {
    color: #ffffff;
    background: transparent linear-gradient(100deg, #D8CF89 0%, #B9B16D 100%) 0% 0% no-repeat padding-box;
}

@media (max-width: 800px) {
    .navbar {
        height: 60px;
    }

    .logo-img img {
        max-width: 120px;
        max-height: 30px;
    }

    .display-none {
        display: none;
    }

    .p-right {
        padding-right: 0px;
    }

    footer {
        padding-left: 20px;
        padding-right: 20px;
    }
}
